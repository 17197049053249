@import "../../Theme/Styles/Legoland/LegolandVars.scss";

.navigate-up-button {
  width: 3.5rem;
  height: 3rem;
  position: fixed;
  bottom: 15px;
  right: 25px;
  background-color: $cyan !important;
  border-color: $cyan !important;
  z-index: 3000;
}

.navigate-up-button-hide {
  opacity: 0;
  animation: hide 0.75s ease-out;
}

.navigate-up-button-show {
  animation: show 0.75s ease-out;
}

@keyframes hide {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(100px);
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}